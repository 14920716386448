import { apiHttpService } from '@/api'
import { getWorkspaceId, getRouteDetailId } from '@/api/utils/route_params'

const processApiResponse = results => results.map(result => {
  return {
    id: result.id,
    name: result.pdf_name,
    category: result.category,
    createdAt: result.created_at,
    url: result.pdf
  }
})

export default {
  async getPdfsList () {
    try {
      const response = await apiHttpService({
        url: '/workspaces/' + getWorkspaceId() + '/documents/' + getRouteDetailId() + '/pdfs/',
        method: 'GET',
        transformResponse: [data => {
          return processApiResponse(data.results)
        }]
      })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
