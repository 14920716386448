<template>
  <v-tabs
    v-model="localActiveTab"
    background-color="transparent"
    slider-color="primary"
    show-arrows
  >
    <v-tab
      v-for="tab in tabs"
      :key="tab.key"
      :href="tab.href"
      :disabled="disabled"
    >
      {{ tab.label }}
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  name: 'TabIterator',
  props: {
    tabs: { type: Array, default: () => { return [] } },
    activeTab: { type: String, default: '' },
    disabled: { type: Boolean, default: false }
  },
  computed: {
    localActiveTab: {
      get () {
        return this.activeTab
      },
      set (newTab) {
        this.$emit('update:active-tab', newTab)
      }
    }
  }
}
</script>
