<template>
  <v-menu
    v-model="toolsMenu"
    offset-y
    :close-on-content-click="false"
    :nudge-width="250"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        :icon="$vuetify.breakpoint.smAndDown"
        text
        rounded
        :disabled="processing"
        v-on="on"
      >
        <v-icon :left="$vuetify.breakpoint.mdAndUp">
          {{ $vuetify.icons.values.menu }}
        </v-icon>
        <span v-if="$vuetify.breakpoint.mdAndUp">
          {{ $t('documents.detail.tools.label') }}
        </span>
      </v-btn>
    </template>
    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-avatar tile>
            <v-icon color="primary darken-4">
              {{ $vuetify.icons.values.document }}
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content
            v-if="documentIsAvailable"
          >
            <v-list-item-title>
              {{ $t('documentCategories.' + documentCategory) }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ documentContent.document.documentNumber }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-list>
        <v-subheader v-if="supportedFlipTargets">
          {{ $t('general.actions') }}
        </v-subheader>
        <v-list-item
          v-if="copyIsSupported"
          ripple
          :disabled="disableCopy"
          @click="copyDocument(documentCategory)"
        >
          <v-list-item-icon>
            <v-icon
              :disabled="disableCopy"
            >
              {{ $vuetify.icons.values.documentCopy }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('documents.detail.tools.copy') }}</v-list-item-title>
        </v-list-item>
        <template v-for="category of supportedFlipTargets">
          <v-list-item
            :key="category"
            ripple
            :disabled="disableFlip"
            @click="flipDocument(category)"
          >
            <v-list-item-icon>
              <v-icon
                :disabled="disableFlip"
              >
                {{ $vuetify.icons.values.flipHorizontal }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('documents.detail.tools.flip.' + category) }}</v-list-item-title>
          </v-list-item>
        </template>
        <v-divider v-if="supportedFlipTargets && deleteIsSupported" />
        <v-subheader v-if="deleteIsSupported">
          {{ $t('general.danger') }}
        </v-subheader>
        <v-list-item
          v-if="deleteIsSupported"
          color="error"
          ripple
          :disabled="!hasWorkspacePermission('delete_documents_document')"
          @click="$emit('update:open-delete-dialog', true)"
        >
          <v-list-item-icon>
            <v-icon
              :disabled="!hasWorkspacePermission('delete_documents_document')"
              color="error"
            >
              {{ $vuetify.icons.values.deleteFile }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('documents.detail.tools.delete') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ToolsMenu',
  props: {
    documentCategory: { type: String, default: '' },
    documentContent: { type: Object, default: () => { return {} } },
    documentIsAvailable: { type: Boolean, default: false },
    openDeleteDialog: { type: Boolean, default: false },
    processing: { type: Boolean, default: false },
    error: { type: Boolean, default: false }
  },
  data () {
    return {
      toolsMenu: false,
      documentFlipRules: {
        purchaseOrder: ['invoice', 'proformInvoice'],
        proformInvoice: ['invoice'],
        invoice: ['creditNote']
      },
      supportedCopyTargets: [
        'invoice',
        'creditNote',
        'debitNote',
        'proformInvoice',
        'purchaseOrder'
      ]
    }
  },
  computed: {
    ...mapGetters([
      'hasWorkspacePermission',
      'subscriptionLimitReached'
    ]),
    supportedFlipTargets () {
      return this.documentFlipRules[this.documentCategory]
    },
    copyIsSupported () {
      return this.supportedCopyTargets.indexOf(this.documentCategory) !== -1
    },
    deleteIsSupported () {
      return this.$route.name === 'DocumentsSentDetails'
    },
    disableCopy () {
      return !this.hasWorkspacePermission('add_documents_receivedfile') || this.subscriptionLimitReached
    },
    disableFlip () {
      return this.disableCopy || this.error
    }
  },
  methods: {
    ...mapActions([
      'deleteDocument',
      'setSourceDocument'
    ]),
    async flipDocument (category) {
      const sourceDocument = JSON.parse(JSON.stringify(this.documentContent))
      const referencesAreArray = Array.isArray(sourceDocument.documentReference)
      if (this.documentCategory === 'invoice') {
        sourceDocument.document.invoiceNumber = sourceDocument.document.documentNumber
        sourceDocument.document.invoiceDate = sourceDocument.document.documentDate
      }
      if (this.documentCategory === 'purchaseOrder') {
        sourceDocument.document.purchaseOrderNumber = sourceDocument.document.documentNumber
        sourceDocument.document.purchaseOrderDate = sourceDocument.document.documentDate
      } else {
        const ref = {
          identifier: this.documentCategory,
          text: sourceDocument.document.documentNumber,
          date: sourceDocument.document.documentDate
        }
        referencesAreArray
          ? sourceDocument.document.documentReference.push(ref)
          : sourceDocument.document.documentReference = [ref]
      }
      sourceDocument.document.documentNumber = null
      sourceDocument.document.documentDate = null
      await this.setSourceDocument(sourceDocument)
      this.$router.push({ name: 'DocumentsFlip', params: { category: category } })
    },
    async copyDocument (category) {
      const sourceDocument = JSON.parse(JSON.stringify(this.documentContent))
      sourceDocument.document.documentNumber = null
      sourceDocument.document.documentDate = null
      await this.setSourceDocument(sourceDocument)
      this.$router.push({ name: 'DocumentsCopy', params: { category: category } })
    }
  }
}
</script>
