<template>
  <v-col cols="12">
    <p class="text-h6 font-weight-regular">
      {{ $t('documents.detail.tabs.attachments') }}
    </p>
    <v-list dense>
      <v-data-iterator
        :items="attachmentsList"
        :items-per-page="paginationOptions.itemsPerPage"
        :footer-props="{
          itemsPerPageOptions: paginationOptions.itemsRange,
          showCurrentPage: true,
          showFirstLastPage: true,
        }"
      >
        <template v-slot:header>
          <table-toolbar
            :loading-data="loading"
            :context="tableToolbarContext"
            @refreshData="getAttachmentsList"
          />
        </template>
        <template v-slot:item="props">
          <v-list-item two-line>
            <v-list-item-icon class="mt-3">
              <v-icon>
                {{ $vuetify.icons.values.file }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-body-2">
                {{ getName(props.item.name) }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-truncate">
                {{ $t('documents.detail.emails.createdAt') }}: {{ getDateTimeValue(props.item.createdAt, 'long') }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                icon
                color="blue"
                :disabled="!props.item.url || !hasWorkspacePermission('view_documents_attachment')"
                :href="props.item.url"
                target="_blank"
              >
                <v-icon>
                  {{ $vuetify.icons.values.download }}
                </v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <v-btn
                icon
                :disabled="showAttachmentDeleteDialog || !hasWorkspacePermission('delete_documents_attachment')"
                color="pink"
                @click.native.stop="openAttachmentDeleteDialog(getAttachmentData(props.item))"
              >
                <v-icon>
                  {{ $vuetify.icons.values.deleteFile }}
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-data-iterator>
      <v-dialog
        v-model="showAttachmentDeleteDialog"
        persistent
        max-width="700px"
      >
        <v-card>
          <v-card-title class="red lighten-4 elevation-2">
            <span class="text-h5 red--text text--darken-4">{{ $t('documents.detail.attachments.deleteDialog.title') }}</span>
          </v-card-title>
          <v-card-text class="py-5">
            <p v-if="noAttachmentDeleteStatus">
              <span class="text-h6">{{ targetAttachment.name }}</span>
            </p>
            <span v-if="noAttachmentDeleteStatus">{{ $t('documents.detail.attachments.deleteDialog.body') }}</span>
            <v-slide-x-transition>
              <alert
                v-show="success"
                type="success"
                :text="$t('documents.detail.attachments.deleteDialog.success')"
              />
            </v-slide-x-transition>
            <v-slide-x-transition>
              <alert
                v-show="error"
                type="error"
                :text="$t('documents.detail.attachments.deleteDialog.error')"
              />
            </v-slide-x-transition>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              text
              rounded
              @click.native="closeAttachmentDeleteDialog()"
            >
              {{ $t('documents.detail.attachments.deleteDialog.decline') }}
            </v-btn>
            <v-btn
              v-if="noAttachmentDeleteStatus"
              color="primary"
              text
              rounded
              :loading="loading"
              @click.native="deleteAttachmentRequest(targetAttachment.id)"
            >
              {{ $t('documents.detail.attachments.deleteDialog.confirm') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-list>
  </v-col>
</template>

<script>
import api from '@/api/modules/attachments'
import { mapGetters } from 'vuex'
import { getDateTimeValue } from '@/mixins/dates'
import Alert from '@/components/alerts/Alert'
import TableToolbar from '@/components/toolbars/TableToolbar'

export default {
  name: 'DocumentAttachments',
  components: {
    Alert,
    TableToolbar
  },
  mixins: [getDateTimeValue],
  data () {
    return {
      attachmentsList: [],
      tableToolbarContext: {
        enableFilters: false,
        enableTitle: false,
        enableRefresh: true,
        title: null
      },
      showAttachmentDeleteDialog: false,
      success: false,
      error: false,
      loading: false,
      targetAttachment: {},
      paginationOptions: {
        itemsPerPage: 15,
        itemsRange: [15, 30, 50]
      }
    }
  },
  computed: {
    ...mapGetters([
      'hasWorkspacePermission'
    ]),
    noAttachmentDeleteStatus () {
      return !this.success && !this.error
    }
  },
  mounted () {
    this.getAttachmentsList()
    this.$bus.on('get:document-attachments', this.getAttachmentsList)
  },
  beforeDestroy () {
    this.$bus.off('get:document-attachments', this.getAttachmentsList)
  },
  methods: {
    getName (item) {
      try {
        if (item && item.length > 0) return item
        else return this.$t('general.na')
      } catch (err) {
        return this.$t('general.na')
      }
    },
    getAttachmentData (item) {
      return {
        id: item.id,
        name: this.getName(item.name)
      }
    },
    openAttachmentDeleteDialog (attachmentData) {
      this.showAttachmentDeleteDialog = true
      this.targetAttachment = attachmentData
    },
    closeAttachmentDeleteDialog () {
      this.showAttachmentDeleteDialog = false
      this.targetAttachment = {}
      this.error = false
      this.success = false
    },
    async getAttachmentsList () {
      const result = await api.getAttachmentsList()
      this.attachmentsList = [...result]
    },
    async deleteAttachmentRequest (id) {
      if (id) {
        try {
          this.loading = true
          const response = await api.deleteAttachmentRequest(id)
          if (response.status >= 200 && response.status < 300) {
            this.success = true
            this.getAttachmentsList()
          } else {
            this.error = true
          }
        } catch (error) {
          this.error = true
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>
