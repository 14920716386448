<template>
  <v-container
    fluid
    class="pt-0"
  >
    <v-row>
      <v-col
        cols="12"
      >
        <document-toolbar
          v-if="documentIsAvailable"
          :document-category="documentCategory"
          :document-detail="documentDetail"
          :document-content="documentContent"
          :document-is-available="documentIsAvailable"
          :processing="processing"
          :error="error"
        />
        <tabs-iterator
          :tabs="tabs"
          :active-tab.sync="activeTab"
          :disabled="!success"
        />
        <card-layout
          v-if="detailDataIsLoaded"
          disable-title
          class="mt-3"
          content-class="text--primary"
        >
          <template
            v-slot:content
          >
            <processing-state
              v-if="processing"
              @refreshData="getDocumentData"
            />
            <error-state
              v-if="error"
              :document-detail="documentDetail"
            />
            <component
              :is="documentTemplate"
              v-if="!error && !processing && documentIsAvailable"
              :document-detail="documentDetail"
              :document-content="documentContent"
              :document-category="documentCategory"
              :document-is-available="documentIsAvailable"
              :active-tab="activeTab"
              :display-recipients="displayRecipients"
            >
              <template v-slot:pdf-list>
                <document-pdfs />
              </template>
              <template v-slot:attachments-list>
                <document-attachments />
              </template>
              <template v-slot:email-recipients-list>
                <email-recipients
                  v-if="displayRecipients"
                  :recipients="documentContent.treatment.recipients"
                />
              </template>
              <template v-slot:email-list>
                <document-emails />
              </template>
              <template v-slot:messages-list>
                <message-history
                  :params="messagesParams"
                />
              </template>
            </component>
          </template>
        </card-layout>
      </v-col>
      <file-upload
        :processing="processing"
        :success="success"
        :error="error"
      />
    </v-row>
  </v-container>
</template>

<script>
import api from '@/api/modules/documents'
import { getTabsList } from '@/features/documents/data/tabs'
import { mapGetters } from 'vuex'
import CardLayout from '@/components/layout/CardLayout'
import DocumentToolbar from '@/components/toolbars/DocumentToolbar'
import TabsIterator from '@/components/tabs/TabsIterator'
import DetailsPlaceholder from '@/components/service/DetailsPlaceholder'
import DocumentPdfs from '@/components/details/Pdfs'
import DocumentAttachments from '@/components/details/Attachments'
import EmailRecipients from '@/components/details/Recipients'
import DocumentEmails from '@/components/details/Emails'
import MessageHistory from '@/components/messages/MessageHistory'
import FileUpload from '@/components/bottom_sheets/FileUpload'

export default {
  name: 'DocumentDetail',
  components: {
    CardLayout,
    DocumentToolbar,
    TabsIterator,
    FallbackTemplate: () => ({
      component: import('@/features/documents/components/details/FallbackTemplate'),
      loading: DetailsPlaceholder
    }),
    ProcessingState: () => ({
      component: import('@/features/documents/components/details/ProcessingState'),
      loading: DetailsPlaceholder
    }),
    ErrorState: () => ({
      component: import('@/features/documents/components/details/ErrorState'),
      loading: DetailsPlaceholder
    }),
    invoices: () => ({
      component: import('@/features/documents/components/details/Invoice'),
      loading: DetailsPlaceholder
    }),
    purchaseOrders: () => ({
      component: import('@/features/documents/components/details/Order'),
      loading: DetailsPlaceholder
    }),
    DocumentPdfs,
    DocumentAttachments,
    EmailRecipients,
    DocumentEmails,
    MessageHistory,
    FileUpload
  },
  data () {
    return {
      activeTab: 'docHead',
      documentDetail: {},
      documentContent: {}
    }
  },
  computed: {
    ...mapGetters([
      'getSupportedDocuments',
      'getDocumentsGroup'
    ]),
    processing () {
      return this.documentDetail.status === 'processing'
    },
    error () {
      return this.documentDetail.status === 'error'
    },
    success () {
      return this.documentDetail.status === 'success'
    },
    tabs () {
      return getTabsList(this.documentsGroup).map(tab => {
        return {
          ...tab,
          label: this.$t(tab.label)
        }
      })
    },
    documentTemplate () {
      const template = this.documentsGroup

      return template || 'fallback-template'
    },
    displayRecipients () {
      return Array.isArray(this.documentContent.treatment.recipients) &&
        this.documentContent.treatment.recipients.length > 0
    },
    messagesParams () {
      return this.documentDetail
        ? [
          { id: this.documentDetail.sourceFiles, content: 'receivedFile' },
          { id: this.documentDetail.id, content: 'document' }
        ]
        : []
    },
    detailDataIsLoaded () {
      return this.$objectHasValues(this.documentDetail)
    },
    documentIsAvailable () {
      return Object.keys(this.documentContent).length > 0
    },
    documentCategory () {
      return this.documentDetail.docType
    },
    documentsGroup () {
      return this.getDocumentsGroup(this.documentDetail.docType)
    }
  },
  mounted () {
    this.getDocumentData()
  },
  methods: {
    async getDocumentData () {
      this.loadingData = true
      const documentDetail = await api.getDocumentDetail()
      this.documentDetail = { ...documentDetail }

      if (documentDetail.status === 'processing') {
        setTimeout(function () {
          this.getDocumentData()
        }.bind(this), 2000)
      }

      if (documentDetail.url) {
        const documentContent = await api.getDocumentContent(this.documentDetail.url)
        this.documentContent = { ...documentContent }
      }
      this.loadingData = false
    }
  }
}
</script>
