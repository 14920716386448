import { apiHttpService } from '@/api'
import { getWorkspaceId, getRouteDetailId } from '@/api/utils/route_params'

const processApiResponse = results => results.map(result => {
  return {
    id: result.uuid,
    objectType: result.object_type,
    objectId: result.object_id,
    status: result.status,
    subject: result.subject,
    attachments: result.context.attachments || [],
    createdAt: result.created_at,
    updatedAt: result.updated_at
  }
})

export default {
  async getEmailsList () {
    try {
      const response = await apiHttpService({
        url: '/workspaces/' + getWorkspaceId() + '/documents/' + getRouteDetailId() + '/emails/',
        method: 'GET',
        transformResponse: [data => {
          return processApiResponse(data.results)
        }]
      })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
