<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-row>
      <v-col cols="3">
        <v-skeleton-loader
          class="ml-4"
          type="image"
        />
      </v-col>
      <v-col cols="9">
        <v-row>
          <v-col cols="4">
            <v-skeleton-loader
              class="ml-3"
              type="chip"
            />
          </v-col>
          <v-col cols="4">
            <v-skeleton-loader
              class="ml-3"
              type="chip"
            />
          </v-col>
          <v-col cols="4">
            <v-skeleton-loader
              class="ml-3"
              type="chip"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-skeleton-loader
              class="ml-3"
              type="chip"
            />
          </v-col>
          <v-col cols="4">
            <v-skeleton-loader
              class="ml-3"
              type="chip"
            />
          </v-col>
          <v-col cols="4">
            <v-skeleton-loader
              class="ml-3"
              type="chip"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-skeleton-loader
              class="ml-3"
              type="chip"
            />
          </v-col>
          <v-col cols="4">
            <v-skeleton-loader
              class="ml-3"
              type="chip"
            />
          </v-col>
          <v-col cols="4">
            <v-skeleton-loader
              class="ml-3"
              type="chip"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-skeleton-loader type="article" />
      </v-col>
      <v-col cols="3">
        <v-skeleton-loader type="article" />
      </v-col>
      <v-col cols="3">
        <v-skeleton-loader type="article" />
      </v-col>
      <v-col cols="3">
        <v-skeleton-loader type="article" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-skeleton-loader
          type="list-item-two-line"
        />
      </v-col>
      <v-col cols="12">
        <v-skeleton-loader
          type="list-item-two-line"
        />
      </v-col>
      <v-col cols="12">
        <v-skeleton-loader
          type="list-item-two-line"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-skeleton-loader
          class="ml-3"
          type="heading"
        />
      </v-col>
      <v-col cols="4">
        <v-skeleton-loader
          class="ml-3"
          type="heading"
        />
      </v-col>
      <v-col cols="4">
        <v-skeleton-loader
          class="ml-3"
          type="heading"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'TablePlaceholder'
}
</script>
