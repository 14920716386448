const tabs = {
  invoices: [
    { key: 'tab-docHead', href: '#docHead', label: 'documents.detail.tabs.overview' },
    { key: 'tab-docLines', href: '#docLines', label: 'documents.detail.tabs.lines' },
    { key: 'tab-docFiles', href: '#docFiles', label: 'documents.detail.tabs.files' },
    { key: 'tab-docEmails', href: '#docEmails', label: 'documents.detail.tabs.emails' },
    { key: 'tab-docProcessDetails', href: '#docProcessDetails', label: 'documents.detail.tabs.processDetails' }
  ],
  purchaseOrders: [
    { key: 'tab-docHead', href: '#docHead', label: 'documents.detail.tabs.overview' },
    { key: 'tab-docLines', href: '#docLines', label: 'documents.detail.tabs.lines' },
    { key: 'tab-docTerms', href: '#docTerms', label: 'documents.detail.tabs.terms' },
    { key: 'tab-docFiles', href: '#docFiles', label: 'documents.detail.tabs.files' },
    { key: 'tab-docEmails', href: '#docEmails', label: 'documents.detail.tabs.emails' },
    { key: 'tab-docProcessDetails', href: '#docProcessDetails', label: 'documents.detail.tabs.processDetails' }
  ]
}

export const getTabsList = documentsGroup => {
  return tabs[documentsGroup] || []
}
