<template>
  <v-toolbar
    v-if="documentIsAvailable"
    class="transparent"
    flat
  >
    <v-toolbar-title>
      {{ $t('documentCategories.' + documentCategory) }}
    </v-toolbar-title>
    <v-divider
      class="mx-4"
      inset
      vertical
    />
    <document-status-chip
      :document-is-available="documentIsAvailable"
      :document-detail="documentDetail"
    />
    <v-chip
      v-if="documentContent.treatment.isPushEmail"
      color="primary--text"
      :input-value="true"
    >
      <v-icon :left="$vuetify.breakpoint.mdAndUp">
        {{ $vuetify.icons.values.email }}
      </v-icon>
      <span v-if="$vuetify.breakpoint.mdAndUp">
        {{ $t('general.sendByMail') }}
      </span>
    </v-chip>
    <v-spacer />
    <document-tools-menu
      :document-category="documentCategory"
      :document-content="documentContent"
      :document-is-available="documentIsAvailable"
      :open-delete-dialog.sync="openDeleteDialog"
      :processing="processing"
      :error="error"
    />
    <document-delete-dialog
      :open-delete-dialog="openDeleteDialog"
      :document-detail="documentDetail"
      @close-delete-dialog="openDeleteDialog = false"
    />
  </v-toolbar>
</template>

<script>
import DocumentStatusChip from '@/features/documents/components/chips/DocumentStatusChip'
import DocumentToolsMenu from '@/components/menus/DocumentToolsMenu'
import DocumentDeleteDialog from '@/components/modals/document/Delete'

export default {
  name: 'DocumentToolbar',
  components: {
    DocumentStatusChip,
    DocumentToolsMenu,
    DocumentDeleteDialog
  },
  props: {
    documentCategory: { type: String, default: '' },
    documentDetail: { type: Object, default: () => { return {} } },
    documentContent: { type: Object, default: () => { return {} } },
    documentIsAvailable: { type: Boolean, default: false },
    processing: { type: Boolean, default: false },
    error: { type: Boolean, default: false }
  },
  data () {
    return {
      openDeleteDialog: false
    }
  }
}
</script>
