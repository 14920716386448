<template>
  <v-col cols="12">
    <p class="text-h6 font-weight-regular">
      {{ $t('documents.detail.options.emailRecipients') }}
    </p>
    <v-list dense>
      <v-data-iterator
        :items="recipients"
        no-data-text=""
        hide-default-footer
        disable-pagination
      >
        <template v-slot:item="{ item }">
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="primary">
                {{ $vuetify.icons.values.contactEmail }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-body-2">
                {{ item }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-data-iterator>
    </v-list>
  </v-col>
</template>

<script>
export default {
  name: 'EmailRecipients',
  props: {
    recipients: { type: Array, default: () => { return [] } }
  }
}
</script>
