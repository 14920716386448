import { apiHttpService } from '@/api'
import { getWorkspaceId, getRouteDetailId } from '@/api/utils/route_params'

const processApiResponse = results => results.map(result => {
  return {
    id: result.id,
    name: result.attachment_name,
    createdAt: result.created_at,
    url: result.attachment
  }
})

export default {
  async getAttachmentsList () {
    try {
      const response = await apiHttpService({
        url: '/workspaces/' + getWorkspaceId() + '/documents/' + getRouteDetailId() + '/attachments/',
        method: 'GET',
        params: {
          limit: 100
        },
        transformResponse: [data => {
          return processApiResponse(data.results)
        }]
      })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async deleteAttachmentRequest (id) {
    try {
      const response = await apiHttpService({
        url: '/workspaces/' + getWorkspaceId() + '/documents/' + getRouteDetailId() + '/attachments/' + id,
        method: 'DELETE'
      })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
