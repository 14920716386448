<template>
  <div class="text-center">
    <v-bottom-sheet
      v-model="sheetState"
      eager
      inset
      :hide-overlay="!success"
      class="white"
    >
      <v-sheet>
        <v-toolbar
          color="primary"
          dark
        >
          <v-toolbar-title v-if="error">
            {{ $t('general.dropzone.documentError') }}
          </v-toolbar-title>
          <v-toolbar-title v-else>
            {{ $t('general.dropzone.title') }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="sheetState = false"
          >
            <v-icon>
              {{ $vuetify.icons.values.clear }}
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-progress-linear
          v-if="processing"
          indeterminate
        />
        <vue2-dropzone
          v-if="success"
          id="dropzone"
          ref="documentsAttachments"
          :options="dropzoneOptions"
          @vdropzone-sending="sendingEvent"
          @vdropzone-queue-complete="$bus.emit('get:document-attachments')"
        />
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { getWorkspaceId, getRouteDetailId } from '@/api/utils/route_params'
import { auth } from '@/keycloak'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  name: 'FileUpload',
  components: { vue2Dropzone },
  props: {
    processing: { type: Boolean, default: true },
    error: { type: Boolean, default: false },
    success: { type: Boolean, default: false }
  },
  data () {
    return {
      sheetState: false,
      dropzoneOptions: {
        url: process.env.VUE_APP_API_BASE_URL + '/workspaces/' + getWorkspaceId() + '/documents/' + getRouteDetailId() + '/attachments/',
        thumbnailWidth: 150,
        addRemoveLinks: true,
        maxFilesize: 5,
        dictDefaultMessage: '<h1 class="text-h5" style="padding: 68px 0">' + this.$t('general.dropzone.drop') + '</h1>',
        dictFallbackMessage: this.$t('general.dropzone.fallbackMessage'),
        dictFallbackText: this.$t('general.dropzone.fallbackText'),
        dictFileTooBig: this.$t('general.dropzone.fileTooBig'),
        dictInvalidFileType: this.$t('general.dropzone.invalidFileType'),
        dictResponseError: this.$t('general.dropzone.responseError'),
        dictCancelUpload: this.$t('general.dropzone.cancelUpload'),
        dictUploadCanceled: this.$t('general.dropzone.uploadCanceled'),
        dictCancelUploadConfirmation: this.$t('general.dropzone.cancelUploadConfirmation'),
        dictRemoveFile: this.$t('general.dropzone.removeFile'),
        dictMaxFilesExceeded: this.$t('general.dropzone.maxFilesExceeded'),
        headers: {
          Authorization: `Bearer ${auth.token()}`,
          Accept: 'application/json',
          'Cache-Control': '',
          'X-Requested-With': 'XMLHttpRequest'
        }
      }
    }
  },
  created () {
    this.$bus.on('open:attachments-sheet', this.openSheet)
  },
  beforeDestroy () {
    this.$bus.off('open:attachments-sheet', this.openSheet)
  },
  methods: {
    openSheet () {
      this.sheetState = true
    },
    sendingEvent (file, xhr, formData) {
      formData.append('document', getRouteDetailId())
      formData.append('attachment', file)
    }
  }
}
</script>

<style>
  .dropzone {
    max-height: 276px;
    overflow-y: auto;
  }
</style>
