<template>
  <v-col cols="12">
    <p class="text-h6 font-weight-regular">
      {{ $t('documents.detail.options.createdEmails') }}
    </p>
    <v-list>
      <v-data-iterator
        :items="emailsList"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:header>
          <table-toolbar
            :loading-data="loadingEmails"
            :context="tableToolbarContext"
            @refreshData="getEmailsList"
          />
        </template>
        <template v-slot:item="props">
          <v-list-group>
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon :color="getStatusColor(props.item.status)">
                  {{ getStatusIcon(props.item.status) }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="text-body-2">
                  {{ props.item.subject || $t('general.noSubject') }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-truncate">
                  {{ $t('general.emailStatus.' + props.item.status) }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="text-truncate">
                  {{ $t('documents.detail.emails.createdAt') }}:
                  {{ getDateTimeValue(props.item.createdAt, 'long') }} /
                  {{ $t('documents.detail.emails.updatedAt') }}:
                  {{ getDateTimeValue(props.item.updatedAt, 'long') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
            <v-subheader inset>
              {{ $t('general.emailAttachments') }}
            </v-subheader>
            <v-list-item
              v-for="attachment in props.item.attachments"
              v-show="props.item.attachments.length > 0"
              :key="attachment.object_type + attachment.object_id"
            >
              <v-list-item-icon>
                <v-icon>
                  {{ getEmailAttachmentIcon(attachment.object_type) }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{
                    $t('general.emailAttachmentTypes.' + attachment.object_type)
                  }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="props.item.attachments.length === 0">
              <v-list-item-icon>
                <v-icon>
                  {{ $vuetify.icons.values.info }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('general.noAttachment') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </v-data-iterator>
    </v-list>
  </v-col>
</template>

<script>
import api from '@/api/modules/emails'
import { getDateTimeValue } from '@/mixins/dates'
import TableToolbar from '@/components/toolbars/TableToolbar'

export default {
  name: 'DocumentEmails',
  components: {
    TableToolbar
  },
  mixins: [getDateTimeValue],
  data () {
    return {
      emailsList: [],
      loadingEmails: false,
      tableToolbarContext: {
        enableFilters: false,
        enableTitle: false,
        enableRefresh: true,
        title: null
      },
      statusColors: {
        not_sent: 'error',
        queued: '',
        sent: 'blue',
        opened: 'success',
        clicked: 'success',
        bounce: 'error',
        spam: 'warning',
        blocked: 'error'
      },
      statusIcons: {
        not_sent: this.$vuetify.icons.values.error,
        queued: this.$vuetify.icons.values.loading,
        sent: this.$vuetify.icons.values.success,
        opened: this.$vuetify.icons.values.emailOpen,
        clicked: this.$vuetify.icons.values.clicked,
        bounce: this.$vuetify.icons.values.undo,
        spam: this.$vuetify.icons.values.warning,
        blocked: this.$vuetify.icons.values.block
      }
    }
  },
  mounted () {
    this.getEmailsList()
  },
  methods: {
    getStatusColor (status) {
      return this.statusColors[status]
    },
    getStatusIcon (status) {
      return this.statusIcons[status]
    },
    getEmailAttachmentIcon (objectType) {
      const iconMap = {
        document_pdf: this.$vuetify.icons.values.pdf,
        attachment: this.$vuetify.icons.values.file
      }
      return iconMap[objectType] || ''
    },
    async getEmailsList () {
      this.loadingEmails = true
      const result = await api.getEmailsList()
      this.emailsList = [...result]
      this.loadingEmails = false
    }
  }
}
</script>
