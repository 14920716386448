<template>
  <v-dialog
    v-model="dialogState"
    scrollable
    transition="slide-y-reverse-transition"
    max-width="700px"
  >
    <v-card>
      <v-card-title class="red lighten-4">
        <span class="text-h5 red--text text--darken-4">
          {{ $t('documents.delete.title') }}
        </span>
      </v-card-title>
      <v-card-text class="py-3">
        <v-row>
          <v-col
            v-show="!success && !error"
            cols="12"
          >
            <p>
              <span class="text-h6">
                {{ $t('documentCategories.' + documentDetail.docType) }}: {{ documentDetail.docNum }}
              </span>
            </p>
            <span>{{ $t('documents.delete.body') }}</span>
          </v-col>
        </v-row>
        <full-screen-alert
          :show-alert="success"
          type="success"
          :text="$t('documents.delete.success')"
        />
        <full-screen-alert
          :show-alert="error"
          type="error"
          :text="$t('documents.delete.error')"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-show="!success"
          color="primary"
          text
          rounded
          @click="close"
        >
          {{ $t('general.close') }}
        </v-btn>
        <v-btn
          v-show="!success && !error"
          color="primary"
          text
          rounded
          :loading="loading"
          @click="deleteAction"
        >
          {{ $t('general.delete') }}
        </v-btn>
        <v-slide-x-transition>
          <v-btn
            v-show="success"
            text
            rounded
            color="primary"
            @click="$router.replace({ name: 'DocumentsSent' })"
          >
            {{ $t('general.ok') }}
          </v-btn>
        </v-slide-x-transition>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from '@/api/modules/documents'
import FullScreenAlert from '@/components/alerts/FullScreenAlert'

export default {
  name: 'DocumentDeleteDialog',
  components: {
    FullScreenAlert
  },
  props: {
    documentDetail: { type: Object, default: () => { return {} } },
    openDeleteDialog: { type: Boolean, default: false }
  },
  data () {
    return {
      loading: false,
      success: false,
      error: false
    }
  },
  computed: {
    dialogState: {
      get () {
        return this.openDeleteDialog
      },
      set () {
        this.close()
      }
    }
  },
  watch: {
    success: function (success) {
      if (success) {
        setTimeout(function () {
          this.$router.replace({ name: 'DocumentsSent' })
        }.bind(this), 700)
      }
    }
  },
  methods: {
    close () {
      this.$emit('close-delete-dialog')
      this.loading = false
      this.success = false
      this.error = false
    },
    async deleteAction () {
      this.loading = true
      const result = await api.deleteDocument()
      this.loading = false
      try {
        if (result.status >= 200 && result.status < 300) {
          this.success = true
        }
      } catch (error) {
        this.error = true
      }
    }
  }
}
</script>
