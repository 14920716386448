<template>
  <v-tooltip
    v-if="documentIsAvailable"
    bottom
  >
    <template v-slot:activator="{ on }">
      <v-chip
        :color="chipProps.color"
        class="mr-3"
        :input-value="true"
        v-on="on"
      >
        <v-icon :left="$vuetify.breakpoint.mdAndUp">
          {{ chipProps.icon }}
        </v-icon>
        <span v-if="$vuetify.breakpoint.mdAndUp">
          {{ $t(chipProps.text) }}
        </span>
      </v-chip>
    </template>
    <span>
      {{ $t(chipProps.tooltipText) }}
    </span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'DocumentStatusChip',
  props: {
    documentIsAvailable: { type: Boolean, default: false },
    documentDetail: { type: Object, default: () => { return {} } }
  },
  data () {
    return {
      chipStatusMap: {
        processing: {
          icon: this.$vuetify.icons.values.loading,
          text: 'general.processing',
          tooltipText: 'general.processingToolTip',
          color: 'grey--text'
        },
        error: {
          icon: this.$vuetify.icons.values.error,
          text: 'general.error',
          tooltipText: 'general.errorToolTip',
          color: 'error--text'
        },
        success: {
          icon: this.$vuetify.icons.values.info,
          text: 'general.duplicate',
          tooltipText: 'documents.detail.originalAlert',
          color: 'warning--text'
        }
      }
    }
  },
  computed: {
    chipProps () {
      return this.chipStatusMap[this.documentDetail.status] || {}
    }
  }
}
</script>
