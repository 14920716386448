<template>
  <v-col cols="12">
    <p class="text-h6 font-weight-regular">
      {{ $t('documents.detail.tabs.pdfs') }}
    </p>
    <v-list dense>
      <v-data-iterator
        :items="pdfList"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:header>
          <table-toolbar
            :loading-data="loadingPdfs"
            :context="tableToolbarContext"
            @refreshData="getPdfsList"
          />
        </template>
        <template v-slot:item="props">
          <v-list-item>
            <v-list-item-icon>
              <v-icon>
                {{ $vuetify.icons.values.pdf }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-body-2">
                {{ getName(props.item.name) }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-truncate">
                <template v-if="props.item.category">
                  {{ $t('pdfCategories.' + props.item.category) }}
                </template>
              </v-list-item-subtitle>
              <v-list-item-subtitle class="text-truncate">
                {{ $t('documents.detail.emails.createdAt') }}: {{ getDateTimeValue(props.item.createdAt, 'long') }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                icon
                color="blue"
                :disabled="!props.item.url || !hasWorkspacePermission('view_documents_documentpdf')"
                :href="props.item.url"
                target="_blank"
              >
                <v-icon>
                  {{ $vuetify.icons.values.download }}
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-data-iterator>
    </v-list>
  </v-col>
</template>

<script>
import api from '@/api/modules/pdfs'
import { mapGetters } from 'vuex'
import { getDateTimeValue } from '@/mixins/dates'
import TableToolbar from '@/components/toolbars/TableToolbar'

export default {
  name: 'DocumentPdfs',
  components: {
    TableToolbar
  },
  mixins: [getDateTimeValue],
  data () {
    return {
      pdfList: [],
      loadingPdfs: false,
      tableToolbarContext: {
        enableFilters: false,
        enableTitle: false,
        enableRefresh: true,
        title: null
      }
    }
  },
  computed: {
    ...mapGetters([
      'hasWorkspacePermission'
    ])
  },
  mounted () {
    this.getPdfsList()
  },
  methods: {
    getName (item) {
      try {
        if (item && item.length > 0) return item
        else return this.$t('general.na')
      } catch (err) {
        return this.$t('general.na')
      }
    },
    async getPdfsList () {
      this.loadingPdfs = true
      const result = await api.getPdfsList()
      this.pdfList = [...result]
      this.loadingPdfs = false
    }
  }
}
</script>
